
    import AbpBase from "../../../lib/abpbase";
    import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
    import PageRequest from "../../../store/entities/page-request";
    import SelectCampana from "../campana/selectCampana.vue";
    import Campana from "../../../store/entities/OZONE/campana";
    import {
        EstadoParticipacion,
        EstadoParticipacionTree,
    } from "../../../store/entities/OZONE/estadoParticipacion";
    import SubEstadoParticipacion from "../../../store/entities/OZONE/subEstadoParticipacion";
import Participacion from "../../../store/entities/OZONE/participacion";

    class PageSorteoRequest extends PageRequest {
    SearchName: string;
    SearchEmail: string;
    Mailing: string;
    SearchCP: string;
    SearchAddress: string;
    referencia: string;
    fechaInicio: Date;
    fechaDevolucion: Date;
    fechaDevolucionHasta: Date;
    fechaCreacionHasta: Date;
    fechaEnTramiteDesde: Date;
    fechaEnTramiteHasta: Date;
    fechaFin: Date;
    campana: Campana;
    enviado: Boolean;
    pendiente: Boolean;
    devuelto: Boolean;
    enTramite: Boolean;
    }

    class EstadosRequest extends PageRequest {
        campanaId: number;
    }

    @Component({
        components: {
            SelectCampana
        }
    })

    export default class Sorteo extends AbpBase {
        estadosRequest: EstadosRequest = new EstadosRequest();
        numeroGanadores: number = 0;
        numeroSuplentes: number = 0;
        campanaSeleccionada: Campana = null;
        estadoParticipacionParaSorteo: EstadoParticipacion = null;
        subEstadoParticipacionParaSorteo: SubEstadoParticipacion = null;
        loading: boolean = false;
        subEstadoParticipacionList: Array<SubEstadoParticipacion> = new Array<SubEstadoParticipacion>();
        ganadores: Array<Participacion> = new Array<Participacion>();
        suplentes: Array<Participacion> = new Array<Participacion>();
        sorteoRealizado: boolean = true;

        get estadoParticipacionList() {
            return this.$store.state.estadoParticipacion.list;
        }

        created(){
            console.log("OK");
        }
        
        getPage(){
            this.loading = true;
            this.getCampanas();
        }
        
        getCampanas(){
            console.log("Obteniendo campañas...");
        }

        changeCampana(campana) {
            this.formSorteo.campanaId = campana.key;
            this.getEstadoParticipacionByCampana(campana.key);
        }

        changeEstadoParticipacion(estadoSeleccionado){
            this.formSorteo.estadoParticipacionId = estadoSeleccionado.key;
            var estado = this.estadoParticipacionList.find((x) => x.id == estadoSeleccionado.key)
            this.subEstadoParticipacionList = estado.subEstadoParticipaciones;
        }

        changeSubEstadoParticipacion(subEstadoSeleccionado){
            this.formSorteo.estadoParticipacionId = subEstadoSeleccionado.key;
        }

        getEstadoParticipacionByCampana(campanaId: number) {
            this.estadosRequest.campanaId = campanaId;
            console.log("Obteniendo los estados de la campaña " + campanaId);
            this.$store
                .dispatch({
                type: "estadoParticipacion/getAllByCampana",
                data: this.estadosRequest,
                });
        }

        formSorteo: {
            campanaId: number;
            numeroGanadores: number;
            numeroSuplentes: number;
            estadoParticipacionId: number;
            subEstadoParticipacionId: number;
        } = {
            campanaId: 0,
            numeroGanadores: 0,
            numeroSuplentes: 0,
            estadoParticipacionId: null,
            subEstadoParticipacionId: null
        };

        columns = [
            { title: "ID", dataIndex: "id" },
            //{ title: this.L("Nombre"), scopedSlots: { customRender: "func" } }  
            { title: "Nombre", dataIndex: "Nombre" }  
        ]

        sampleData = [
            { id: 10, Nombre: "Joshua"},
            { id: 11, Nombre: "Joshua2"},
            { id: 12, Nombre: "Joshua2"},
            { id: 13, Nombre: "Joshua2"}
        ]
    }
